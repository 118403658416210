import React, { useState } from 'react';
import axios from 'axios';
import config from './config'; // Import the configuration

const ReprocessOrders = () => {
  const [logs, setLogs] = useState([]);
  const [isExecuting, setIsExecuting] = useState(false);

  const handleDeleteFlags = async () => {
    setIsExecuting(true);
    setLogs((prevLogs) => [...prevLogs, 'Deleting processed flags...']);
    try {
      await axios.post(`${config.apiUrl}:${config.apiPort}/api/deleteFlags`);
      setLogs((prevLogs) => [...prevLogs, 'Processed flags deleted.']);
    } catch (error) {
      setLogs((prevLogs) => [...prevLogs, 'Error deleting processed flags.']);
    }
    setIsExecuting(false);
  };

  const handleExecuteMain = async () => {
    setIsExecuting(true);
    setLogs((prevLogs) => [...prevLogs, 'Running Boxcalc main function...']);
    try {
      await axios.post(`${config.apiUrl}:${config.apiPort}/api/executeMain`);
      setLogs((prevLogs) => [...prevLogs, 'Boxcalc main function executed.']);
    } catch (error) {
      setLogs((prevLogs) => [...prevLogs, 'Error executing Boxcalc main function.']);
    }
    setIsExecuting(false);
  };

  return (
    <div className="reprocess-container">
      <button
        className="reprocess-button"
        onClick={handleDeleteFlags}
        disabled={isExecuting}
      >
        Delete Flags
      </button>
      <button
        className="reprocess-button"
        onClick={handleExecuteMain}
        disabled={isExecuting}
      >
        Run Boxcalc
      </button>
      <div className="log-box">
        {logs.map((log, index) => (
          <div key={index}>{log}</div>
        ))}
      </div>
    </div>
  );
};

export default ReprocessOrders;
