// BoxTable.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';
import config from './config'; // Import the configuration

axios.interceptors.request.use(function (config) {
  console.log('Request:', config);
  return config;
}, function (error) {
  return Promise.reject(error);
});

const BoxTable = () => {
  const [boxes, setBoxes] = useState([]);
  const [editingBox, setEditingBox] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    length: '',
    width: '',
    height: '',
    tag: '',
  });
  const [error, setError] = useState('');
  const [tags, setTags] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [boxToDelete, setBoxToDelete] = useState(null);

  useEffect(() => {
    fetchBoxes();
    fetchTags();
  }, []);

  const fetchBoxes = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}:${config.apiPort}/api/boxes`);
      setBoxes(response.data);
    } catch (error) {
      console.error('Error fetching boxes:', error);
    }
  };

  const fetchTags = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}:${config.apiPort}/api/tags`);
      setTags(response.data);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  const handleEditClick = (box) => {
    setEditingBox(box);
    setFormData(box);
    setIsModalOpen(true);
  };

  const handleDeleteClick = (box) => {
    setBoxToDelete(box);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    setError('');
    try {
      await axios.post(`${config.apiUrl}/api/deleteBox`, { name: boxToDelete.name });
      fetchBoxes(); // Fetch the updated boxes list
      setIsDeleteModalOpen(false);
      setBoxToDelete(null);
    } catch (error) {
      setError(`Error deleting box: ${error.response ? error.response.data : error.message}`);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSave = async () => {
    setError('');
    try {
      await axios.post(`${config.apiUrl}:${config.apiPort}/api/updateBox`, formData);
      fetchBoxes(); // Fetch the updated boxes list
      setIsModalOpen(false);
    } catch (error) {
      setError(`Error updating box: ${error.response ? error.response.data : error.message}`);
    }
  };

  const handleAddBox = () => {
    setEditingBox(null);
    setFormData({
      name: '',
      length: '',
      width: '',
      height: '',
      tag: '',
    });
    setIsModalOpen(true);
  };

  const handleAddSave = async () => {
    setError('');
    try {
      await axios.post(`${config.apiUrl}:${config.apiPort}/api/addBox`, formData);
      fetchBoxes(); // Fetch the updated boxes list
      setIsModalOpen(false);
    } catch (error) {
      setError(`Error adding box: ${error.response ? error.response.data : error.message}`);
    }
  };

  return (
    <div className="container">
      <h1>Boxcalc2.0</h1>
      {error && <div className="error">{error}</div>}
      <button className="reprocess-button" onClick={handleAddBox}>Add Box</button>
      <table className="excel-table">
        <thead>
          <tr>
            <th>Box Name</th>
            <th>Box Length</th>
            <th>Box Width</th>
            <th>Box Height</th>
            <th>Box Tag</th>
            <th>Box Volume</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {boxes.map((box) => (
            <tr key={box.name}>
              <td>{box.name}</td>
              <td>{box.length}</td>
              <td>{box.width}</td>
              <td>{box.height}</td>
              <td>
                <div className="tag">
                  <span className="tag-color" style={{ backgroundColor: box.color }}></span>
                  {box.tagName}
                </div>
              </td>
              <td>{box.volume}</td>
              <td>
                <button onClick={() => handleEditClick(box)}>
                  <FaEdit />
                </button>
              </td>
              <td>
                <button onClick={() => handleDeleteClick(box)}>
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>{editingBox ? 'Edit Box' : 'Add Box'}</h2>
            <label>
              Name:
              {editingBox ? (
                <span className="readonly-display">{formData.name}</span>
              ) : (
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              )}
            </label>
            <label>
              Length:
              <input
                type="number"
                name="length"
                value={formData.length}
                onChange={handleChange}
              />
            </label>
            <label>
              Width:
              <input
                type="number"
                name="width"
                value={formData.width}
                onChange={handleChange}
              />
            </label>
            <label>
              Height:
              <input
                type="number"
                name="height"
                value={formData.height}
                onChange={handleChange}
              />
            </label>
            <label>
              Tag:
              <select
                name="tag"
                value={formData.tag}
                onChange={handleChange}
              >
                <option value="">Select a tag</option>
                {tags.map(tag => (
                  <option key={tag.tagId} value={tag.tagId}>
                    {tag.name}
                  </option>
                ))}
              </select>
            </label>
            <button onClick={editingBox ? handleSave : handleAddSave}>
              {editingBox ? 'Save' : 'Add'}
            </button>
            <button onClick={() => setIsModalOpen(false)}>Cancel</button>
            {error && <div className="error">{error}</div>}
          </div>
        </div>
      )}

      {isDeleteModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Are you sure you want to delete this box?</h2>
            <p>{boxToDelete && boxToDelete.name}</p>
            <button onClick={handleConfirmDelete}>Yes</button>
            <button onClick={() => setIsDeleteModalOpen(false)}>No</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BoxTable;
