import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './index.css';

const LogAnimation = ({ logs }) => {
    const containerRef = useRef(null);
    const itemRefs = useRef([]);
    itemRefs.current = [];

    useEffect(() => {
        const container = containerRef.current;
    
        if (!logs) return;
    
        const logLines = logs.split('\n').filter(line => line.trim() !== '');
        logLines.forEach((line, index) => {
            const element = document.createElement('div');
            element.className = 'log-line';
            element.innerText = line;
            container.appendChild(element);
            itemRefs.current.push(element);
        });
    
        // Example animation
        logLines.forEach((line, index) => {
            const element = itemRefs.current[index];
            if (line.includes('cannot fit')) {
                gsap.to(element, { color: 'red', duration: 1 });
            } else if (line.includes('fits')) {
                gsap.to(element, { color: 'green', duration: 1 });
            }
        });
    
        gsap.fromTo('.log-line', 
            { opacity: 0, y: 50 },
            { opacity: 1, y: 0, stagger: 0.5, duration: 1 }
        );
    }, [logs]);
    

    return (
        <div ref={containerRef} className="log-container"></div>
    );
};

export default LogAnimation;