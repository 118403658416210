import React, { useState } from 'react';
import axios from 'axios';
import config from './config'; // Import the configuration
import LogAnimation from './logAnimation';

const ProcessOrder = () => {
    const [orderNumber, setOrderNumber] = useState('');
    const [logs, setLogs] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleOrderNumberChange = (e) => {
        setOrderNumber(e.target.value);
    };

    const handleProcessOrder = async () => {
        setLoading(true);
        setError('');
        setLogs('');

        try {
            const response = await axios.post(`${config.apiUrl}:${config.apiPort}/api/process-order`, { orderNumber });
            console.log('Response Data:', response.data); // Debugging
            setLogs(response.data.logs);
        } catch (err) {
            setError(`Error processing order: ${err.response ? err.response.data.error : err.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h1>Single Order Logs</h1>
            <input
                type="text"
                value={orderNumber}
                onChange={handleOrderNumberChange}
                placeholder="Enter order number"
            />
            <button onClick={handleProcessOrder} disabled={loading}>
                {loading ? 'Processing...' : 'Process Order'}
            </button>
            {error && <div style={{ color: 'red' }}>{error}</div>}
            {logs && (
                <textarea
                    rows="10"
                    cols="50"
                    readOnly
                    value={logs}
                    style={{ marginTop: '10px' }}
                />
            )}
            <LogAnimation logs={logs} />
        </div>
    );
};

export default ProcessOrder;
