import './App.css';
import React from 'react';
import Home from './Home';




const App = () => {
  return (
    <a href='/sign-in'>Sign In</a>
  );
};
export default App;

