import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './index.css';
import BoxTable from './BoxTable';
import ReprocessOrders from './ReprocessOrders';
import ProcessOrder from './singleOrderLogs';

const Home = () => {
  return (
    <div className="Home">
    <BoxTable />
    <ReprocessOrders />
    <ProcessOrder />
    </div>
  );
};

export default Home;